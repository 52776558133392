// SelectModel.js
import { Select, FormControl, FormLabel } from '@chakra-ui/react';
import {useState} from "react";

const SelectModel = ({ value, onChange }) => {
    const [model, setModel] = useState('gpt-3.5-turbo');

    return (
        <FormControl>
            <FormLabel>Model</FormLabel>
            <Select value={value} onChange={(e) => onChange(e.target.value)}>
                <option value="gpt-3.5-turbo">GPT-3.5-turbo</option>
                <option value="gpt-4">GPT-4</option>
            </Select>
        </FormControl>
    );
}

export default SelectModel;
