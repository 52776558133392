import React, { createContext, useState, useContext, useEffect } from 'react';
import instance from '../utils/axiosConfig';
const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
    const [sessions, setSessions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchSessions = async () => {
        setIsLoading(true);
        try {
            const response = await instance.get(process.env.REACT_APP_CHATAPI_URL + '/sessions', {
                withCredentials: true
            });
            console.log("SEssions fetched", response.data.sessions);
            setSessions(response.data.sessions);
        } catch (error) {
            console.error('Error fetching sessions:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchSessions();
    }, []);

    const deleteSession = (session_id) => {
        setSessions(prevSessions => prevSessions.filter(session => session._id !== session_id));
    };

    return (
        <SessionContext.Provider value={{ sessions, setSessions, isLoading, deleteSession, refreshSessions: fetchSessions }}>
            {children}
        </SessionContext.Provider>
    );
};

export const useSessions = () => {
    const context = useContext(SessionContext);
    if (context === undefined) {
        throw new Error('useSessions must be used within a SessionProvider');
    }
    return context;
};
