import React, { useEffect, useState } from 'react';
import { HashRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { Box, ChakraProvider, Flex } from '@chakra-ui/react';
import UserCreation from './pages/UserCreation';
import ChatbotIngestion from './components/ChatbotIngestion';
import jwtDecode from 'jwt-decode';
import { refreshToken } from './utils/api';
import Sidebar from './components/Sidebar';
import theme from './styles/theme';
import ChatbotList from "./components/chats/ChatbotList";
import ChatSessions from "./components/chats/ChatSessions";
import ChatPage from "./pages/ChatPage";
import Header from "./components/Header";
import UserAuth from "./components/login/UserAuth";
import ExtendedChatSettings from "./components/chat_settings/ExtendedChatSettings";
import instance from "./utils/axiosConfig";
import ManageAuthKeys from "./components/api/ManageAuthKeys";
import useAxios from "./hooks/useAxios";
import { SessionProvider } from "./hooks/sessionContext";
import { ChatbotProvider } from "./hooks/chatbotContext";
import ReactGA from 'react-ga';
import PageViewAnalytics from "./components/PageViewAnalytics";
import Footer from "./Footer";  // Import the Footer component

function App() {
    const [user, setUser] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLoggedIn') === 'true');
    const [isLoading, setIsLoading] = useState(true);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const fetchUserData = async () => {
        try {
            const response = await instance.get(process.env.REACT_APP_CHAT_URL + '/me', { withCredentials: true });
            setUser(response.data);
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    useEffect(() => {
        if (isLoggedIn) {
            fetchUserData();
        }
    }, [isLoggedIn]);

    const checkLoginStatus = async () => {
        try {
            const response = await instance.get(process.env.REACT_APP_CHAT_URL + '/login/status', { withCredentials: true });
            if (response.data.isLoggedIn) {
                setIsLoggedIn(true);
                localStorage.setItem('isLoggedIn', 'true');
            } else {
                setIsLoggedIn(false);
                localStorage.setItem('isLoggedIn', 'false');
            }
            setIsLoading(false);  // Set loading to false after we know the login status
        } catch (error) {
            setIsLoggedIn(false);
            localStorage.setItem('isLoggedIn', 'false');
            setIsLoading(false);
            console.error("Error checking login status:", error);
        }
    };

    useEffect(() => {
        checkLoginStatus();
    }, []);

    const handleLoginSuccess = () => {
        setIsLoggedIn(true);
        localStorage.setItem('isLoggedIn', 'true');
    };

    const handleLogout = () => {
        setIsLoggedIn(false);
        document.cookie.split(";").forEach(function (c) {
            document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });
        localStorage.setItem('isLoggedIn', 'false');
    };

    return (
        <ChatbotProvider>
            <SessionProvider>
                <ChakraProvider theme={theme}>
                    <Router>
                        <PageViewAnalytics />
                        <Header onMenuClick={toggleSidebar}></Header>
                        <Flex direction="column" minHeight="100vh">
                            <Flex flex="1" height="calc(100vh - 64px)">
                                {isLoggedIn && (
                                    <Sidebar isOpen={isOpen} onToggle={toggleSidebar} onLogout={handleLogout} />
                                )}
                                <Box flex="1" p={4}>
                                    <Routes>
                                        <Route path="/login" element={isLoggedIn ? <Navigate to="/ingestion" /> : <UserAuth onLoginSuccess={handleLoginSuccess} />} />
                                        <Route path="/ingestion" element={isLoggedIn ? <ChatbotIngestion /> : <Navigate to="/login" />} />
                                        <Route path="/settings/:chatbotId" element={isLoggedIn ? <ExtendedChatSettings chatbotId={localStorage.getItem("chat_id")} /> : <Navigate to="/login" />} />
                                        <Route path="/chatbots" element={isLoggedIn ? <ChatbotList /> : <Navigate to="/login" />} />
                                        <Route path="/sessions" element={isLoggedIn ? <ChatSessions /> : <Navigate to="/login" />} />
                                        <Route path="/interface/:chat_id/:session_id?" element={isLoggedIn ? <ChatPage /> : <Navigate to="/login" />} />
                                        <Route path={"/api"} element={isLoggedIn ? <ManageAuthKeys /> : <Navigate to="/login" />} />
                                        <Route path="*" element={<Navigate to="/login" />} />
                                    </Routes>
                                </Box>
                            </Flex>
                            <Footer />
                        </Flex>
                    </Router>
                </ChakraProvider>
            </SessionProvider>
        </ChatbotProvider>
    );
}

export default App;
