// src/components/Footer.js
import React from 'react';
import { Box, Text } from '@chakra-ui/react';

const Footer = () => {
    return (
        <Box as="footer" width="100%" p={4} backgroundColor="gray.700" color="white" textAlign="center">
            <Text>&copy; {new Date().getFullYear()} MindVector LLC. All rights reserved.</Text>
        </Box>
    );
};

export default Footer;
