import React, {useEffect} from 'react';
import axios from 'axios';
import { Box, Button, VStack, useColorModeValue, Text } from '@chakra-ui/react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import GithubLoginButton from '../login/GithubLogin';

const Login = ({ onLoginSuccess }) => {
    const navigate = useNavigate();

    const handleLogin = () => {
        window.location.href = process.env.REACT_APP_AUTH_URL + '/login';
    };
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const token = searchParams.get('token');
        const userId = searchParams.get('userId');
        console.log(token)
        if (token && token !== '') {
            console.log("setting cookie")
            if (window.location.hostname === "localhost") {
                // Set cookie for localhost (without domain, secure, or SameSite attributes)
                document.cookie = `jwt_token=${token};path=/;`;
            } else {
                // Set cookie for production
                document.cookie = `jwt_token=${token};path=/;secure;SameSite=None;domain=.dataspeak.io`;
                document.cookie = `user-id=${userId};path=/;secure;SameSite=None;domain=.dataspeak.io`;

            }

        } else {
            // Handle error or redirect user to login
        }
    }, []);



    return (
        <VStack spacing={4}>
            <Button variant="primary" onClick={handleLogin} size="lg" borderRadius="md" w="100%">Enter DataSpeak</Button>
        </VStack>
    );
};

export default Login;
