import { Box, Button, Text, useColorModeValue } from "@chakra-ui/react";
import { useRef, useState } from "react";

const DragAndDrop = ({ onFileChange, accept }) => {
    const [isDragging, setIsDragging] = useState(false);
    const borderColor = useColorModeValue(isDragging ? "blue.500" : "gray.500", "gray.300");
    const fileInputRef = useRef(null);

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        onFileChange(e.dataTransfer.files[0]);
    };

    const handleClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        onFileChange(e.target.files[0]);
    };

    return (
        <Box
            border="2px dashed"
            borderColor={borderColor}
            padding="20px"
            textAlign="center"
            onDrop={handleDrop}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
        >
            <input type="file" ref={fileInputRef} accept={accept} onChange={handleFileChange} style={{ display: "none" }} />
            <Button onClick={handleClick} colorScheme="blue">
                Choose File
            </Button>
            <Text mt={4}>{isDragging ? "Release to drop the file here" : "Drag and drop a file here, or click the button to select a file."}</Text>
        </Box>
    );
};

export default DragAndDrop;
