// useAxios.js

import axios from 'axios';
import {Box, useToast, Text} from '@chakra-ui/react';

const useAxios = () => {
    const toast = useToast();

    const axiosInstance = axios.create({
        // You can set your default config here, for example:
        baseURL: 'http://api.dataspeak.io/',
        timeout: 100000,
    });

    // Add a response interceptor
    axiosInstance.interceptors.response.use(
        function (response) {
            // Extract the 'message' from the response
            const message = response.data.message || "Request was successful.";

            toast({
                render: () => (
                    <Box p={3} color="white" bg="green.500">
                        <Text fontWeight="bold">Success!</Text>
                        <Text fontWeight={"bold"}>{message}</Text>
                    </Box>
                ),
                duration: 1500,
                isClosable: true,
            });

            return response;
        },
        function (error) {
            // Extract the 'error' from the error response
            console.log("FUNC ERROR")
            const errorMessage = error.response?.data?.error || "Something went wrong.";

            toast({
                render: () => (
                    <Box p={3} color="white" bg="red.500">
                        <Text fontWeight="bold">Error!</Text>
                        <Text fontWeight={"bold"}>{errorMessage}</Text>
                    </Box>
                ),
                duration: 3000,
                isClosable: true,
            });

            return Promise.reject(error);
        }
    );

    return axiosInstance;
};

export default useAxios;
