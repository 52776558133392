import React, { useState } from 'react';
import { Box, Container, Heading, VStack, Divider } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import NameInput from "./ingestion/NameInput";
import IngestionButton from "./ingestion/IngestionButton";
import FileHandler from "./types/FileHandler";
import {useChatbots} from "../hooks/chatbotContext";

const ChatbotIngestion = () => {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const { refreshChatbots } = useChatbots();
    const handleSuccess = (response) => {
        const embeddingNamespace = response.data.id;
        const chatId = response.data.chatbot_id;
        localStorage.setItem('chat_id', chatId);
        localStorage.setItem('session_id', '');
        localStorage.setItem('embedding_namespace', embeddingNamespace);
        refreshChatbots()
        navigate(`/interface/${chatId}`);

    };
    const handleError = (error) => {
        console.log(error);
        // Handle error
    };

    return (
        <Container centerContent>
            <Box p={6} borderWidth={1} borderRadius="lg" shadow="md">
                <Heading mb={4} textAlign="center">Create your bot!</Heading>
                <VStack spacing={4}>
                    <NameInput onChange={e => setName(e.target.value)} />
                    <FileHandler onSuccess={handleSuccess} onError={handleError} mode={"create"} name={name}/>
                    <Divider my={4}/>
                    {/* Additional components and logic can be added here if necessary */}
                </VStack>
            </Box>
        </Container>
    );
};

export default ChatbotIngestion;
