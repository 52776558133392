import React from 'react';
import { List, ListItem, Text, Heading, Spinner, VStack, useColorModeValue, Box, Alert, AlertIcon } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import ChatItem from './ChatItem';
import {useChatbots} from "../../hooks/chatbotContext";

const ChatbotList = () => {
    const { chatbots, isLoading, error, setChatbots } = useChatbots();
    const navigate = useNavigate();
    const textColor = useColorModeValue("primary.900", "primary.100");
    const txtColor = useColorModeValue("gray.600", "gray.400");

    const handleOpenChat = (embedding_namespace, chat_id) => {
        navigate(`/interface/${chat_id}`);
    };

    const handleDelete = (chatbot_id) => {
        setChatbots(prevChatbots => prevChatbots.filter(chatbot => chatbot._id["$oid"] !== chatbot_id));
    };

    return (
        <VStack spacing={6} align="start">
            <Heading size="lg" color={textColor}>Your Chatbots</Heading>
            {isLoading ? (
                <Spinner color="primary.400" />
            ) : error ? (
                <Alert status="error">
                    <AlertIcon />
                    {error}
                </Alert>
            ) : Array.isArray(chatbots) && chatbots.length > 0 ? (
                <List spacing={4} w="100%">
                    {chatbots.map((chatbot) => (
                        <ListItem key={chatbot.embedding_namespace}>
                            <ChatItem
                                name={chatbot.name}
                                onClick={() => handleOpenChat(chatbot.embedding_namespace, chatbot._id["$oid"])}
                                onDelete={() => handleDelete(chatbot._id["$oid"])}
                                chatbot_id={chatbot._id["$oid"]}
                                itemType={"chatbot"}
                                showSettings={true}
                            />
                        </ListItem>
                    ))}
                </List>
            ) : (
                <Text fontStyle="italic" color={txtColor}>No chatbots available</Text>
            )}
        </VStack>
    );
};

export default ChatbotList;
