import React from 'react';
import {
    Flex, Text, Box, IconButton,
    useColorMode, useColorModeValue,
    Icon, Tooltip
} from '@chakra-ui/react';
import { FaBars, FaSun, FaMoon } from 'react-icons/fa';

const Header = ({ onMenuClick }) => {
    const { colorMode, toggleColorMode } = useColorMode(); // Hook to get and toggle color mode
    const bgColor = useColorModeValue("primary.500", "primary.200");
    const textColor = useColorModeValue("white", "primary.900");
    const iconColor = useColorModeValue("white", "primary.900");

    return (
        <Flex bg={bgColor} w="full" p={4} align="center" height={"64px"}>
            <IconButton
                aria-label="Menu"
                icon={<Icon as={FaBars} />}
                onClick={onMenuClick}
                mr={4}
                display={{ base: "block", md: "none" }}
                bg={bgColor}
                color={iconColor}
                _hover={{ bg: bgColor }}
                _active={{ bg: bgColor }}
            />
            <Box flex="1">
                <Text fontSize={["md", "lg", "xl"]} fontWeight="bold" color={textColor}>
                    DataSpeak
                </Text>
            </Box>
            <Tooltip label={`Switch to ${colorMode === 'light' ? 'dark' : 'light'} mode`} aria-label="A tooltip">
                <IconButton
                    aria-label="Toggle dark mode"
                    icon={<Icon as={colorMode === 'light' ? FaMoon : FaSun} />} // Show moon icon for light mode and sun icon for dark mode
                    onClick={toggleColorMode}
                    bg={bgColor}
                    color={iconColor}
                    _hover={{ bg: bgColor }}
                    _active={{ bg: bgColor }}
                />
            </Tooltip>
        </Flex>
    );
};

export default Header;
