// RelevantTextsInput.js
import { FormControl, FormLabel, NumberInput, NumberInputField, Tooltip } from '@chakra-ui/react';

const RelevantTextsInput = ({ value, onChange }) => (
    <FormControl>
        <FormLabel>Relevant texts amount</FormLabel>
        <Tooltip label="Relevant texts are based on your query and help to refine the response. Adjusting the amount can lead to more specific or broader answers." fontSize="md" placement="top">
            <NumberInput min={0} max={100} value={value} onChange={onChange}>
                <NumberInputField />
            </NumberInput>
        </Tooltip>
    </FormControl>
);

export default RelevantTextsInput;
