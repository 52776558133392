import React, { useEffect, useState } from 'react';
import { Flex, useMediaQuery } from '@chakra-ui/react';
import ChatSection from './ChatSection';
import useChat from '../../hooks/useChat';
import { useParams } from "react-router-dom";
import { useSessions } from "../../hooks/sessionContext";
import {useChatbots} from "../../hooks/chatbotContext";

const ChatInterface = () => {
        const [isMobile] = useMediaQuery('(min-width: 768px)');
        const [botName, setBotName] = useState();
        const { chat_id, session_id } = useParams();
        const [isAwaitingResponse, setIsAwaitingResponse] = useState(false);

        const {
                chatId,
                setChatId,
                query,
                setQuery,
                relevantTextsAmount,
                setRelevantTextsAmount,
                model,
                setModel,
                messages,
                setMessages,
                handleModelChange,
                handleRelevantTextsAmountChange,
                handleQueryChange,
                handleChat,
                handleKeyPress,
                templates,
                setTemplates,
                promptTemplates,
                setPromptTemplates
        } = useChat({ chatId: chat_id, sessionId: session_id, setIsAwaitingResponse: setIsAwaitingResponse, isAwaitingResponse: isAwaitingResponse });


        const { chatbots, isLoading, error } = useChatbots();

        useEffect(() => {
                const chatbotData = chatbots.find(chatbot => chatbot._id["$oid"] === chat_id);
                if (chatbotData) {
                        setBotName(chatbotData.name);
                        setPromptTemplates(chatbotData.chat_settings?.prompt_templates);
                        setTemplates(chatbotData.chat_settings?.prompt_templates);
                }
        }, [chatId, chatbots]);

        return (
            <Flex
                direction={isMobile ? "row" : "column"}
                justifyContent="center"
                alignItems="center"
                height="100%"
                width={"100%"}
            >
                    <ChatSection
                        chatName={botName}
                        query={query}
                        messages={messages}
                        handleQueryChange={handleQueryChange}
                        handleChat={handleChat}
                        handleKeyPress={handleKeyPress}
                        isAwaitingResponse={isAwaitingResponse}
                        chatId={chat_id}
                        suggestionClick={(suggestion) => setQuery(suggestion)}
                    />
            </Flex>
        );
};

export default ChatInterface;
