import React from 'react';
import {
    Input,
    Tooltip,
    VStack,
    HStack,
    Icon,
    NumberInput,
    NumberInputField,
    FormLabel,
    FormControl
} from '@chakra-ui/react';
import { FaLink } from 'react-icons/fa';

const URLComponent = ({ onTextChange, onDepthChange, text, depth }) => {
    return (
        <VStack spacing={3} mt={3} mb={3}>
            <HStack spacing={3} alignItems="center" w="100%">
                <Icon as={FaLink} boxSize={5} color="blue.500" />

                <FormControl flexGrow={1}>
                    <FormLabel htmlFor="url-input" fontSize="sm" mb={1}>URL</FormLabel>
                    <Input
                        id="url-input"
                        onChange={onTextChange}
                        placeholder="Paste your URL here"
                        value={text}
                    />
                </FormControl>

                <FormControl width="140px">
                    <FormLabel htmlFor="depth-input" fontSize="sm" mb={1}>Depth</FormLabel>
                    <Tooltip hasArrow label="The number of levels to scrape within the website. Higher depth might take longer." fontSize="xs">
                        <NumberInput max={8} min={0} onChange={onDepthChange} value={depth}>
                            <NumberInputField id="depth-input" placeholder="0-3" />
                        </NumberInput>
                    </Tooltip>
                </FormControl>
            </HStack>
        </VStack>
    );
};

export default URLComponent;
