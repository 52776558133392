import { useState, useEffect } from 'react';
import instance from "../utils/axiosConfig";
import useAxios from "./useAxios";

const useFileHandler = () => {
    const [file, setFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [loadingText, setLoadingText] = useState('Creating your chatbot...');
    const [selectedFileType, setSelectedFileType] = useState(null);
    const loadingTexts = [
        "Creating your chatbot...",
        // ... (rest of your loading texts)
    ];
    const axios = useAxios()

    useEffect(() => {
        let interval;
        if (isUploading) {
            interval = setInterval(() => {
                const randomText = loadingTexts[Math.floor(Math.random() * loadingTexts.length)];
                setLoadingText(randomText);
            }, 2000);
        }
        return () => clearInterval(interval);
    }, [isUploading]);

    const onFileChange = (file) => {
        setFile(file); // Assuming you're selecting one file
    };

    const fileTypeClick = (type) => {
        setSelectedFileType(type);
    }

// A pure function to determine the request headers and data based on the input
    const getRequestDetails = (data, isFile, token, fileType, file, depth) => {
        const commonHeaders = {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json, text/plain, application/pdf, */*'
        };

        if (isFile) {
            const formData = new FormData();
            formData.append('name', data.name);
            formData.append('file', file);
            formData.append('data_type', fileType);

            if(data.repoData) {
                formData.append('data', JSON.stringify(data.repoData));
            }

            return {
                requestData: formData,
                requestHeaders: { ...commonHeaders }
            };
        } else {
            const jsonData = {
                name: data.name,
                text: data.text,
                data_type: fileType
            };

            if (data.repoData) {
                jsonData.data = data.repoData;
            }

            if (fileType === "url" && depth) {
                jsonData.depth = depth; // Add depth to jsonData when fileType is "url"
            }

            return {
                requestData: jsonData,
                requestHeaders: {
                    ...commonHeaders,
                    'Content-Type': 'application/json'
                }
            };
        }
    };

    const handleIngestion = (data, isFile, callbackSuccess, callbackError, depth) => {
        const token = localStorage.getItem('token');
        const { requestData, requestHeaders } = getRequestDetails(data, isFile, token, selectedFileType, file, depth);

        // This is the side-effect part
        setIsUploading(true);

        axios.post(process.env.REACT_APP_INGESTION_URL + '/chatbots', requestData, { withCredentials: true, timeout: 1000*240})
            .then(callbackSuccess)
            .catch(callbackError)
            .finally(() => {
                setIsUploading(false);
            });
    };

    const handleAddDocuments = (chatbotId, data, isFile, callbackSuccess, callbackError, depth) => {
        const token = localStorage.getItem('token');
        const { requestData, requestHeaders } = getRequestDetails(data, isFile, token, selectedFileType, file, depth);
        // This is the side-effect part
        setIsUploading(true);
        console.log(chatbotId)
        console.log(localStorage.getItem('chat_id'))
        axios.put(`${process.env.REACT_APP_INGESTION_URL}/chatbots/${chatbotId}`, requestData, { withCredentials: true })
            .finally(() => {
                setIsUploading(false);
            });
    };




    return {
        file,
        isUploading,
        loadingText,
        selectedFileType,
        onFileChange,
        fileTypeClick,
        handleIngestion,
        getRequestDetails,
        handleAddDocuments
    };
};

export default useFileHandler;
