import React, { useState } from 'react';
import {
    HStack, IconButton, Text, useColorModeValue, Icon, AlertDialog, AlertDialogBody,
    AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay,
    Input, Button, Box, Center, useToast, Modal, ModalOverlay, ModalContent,
    ModalHeader, ModalBody, ModalCloseButton, Code, Select
} from '@chakra-ui/react';
import { FaTrash, FaCog, FaCopy, FaCode} from 'react-icons/fa';  // Add FaCopy for the copy icon
import { useNavigate } from 'react-router-dom';
import useAxios from "../../hooks/useAxios";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';

const ChatItem = ({ name, onClick, onDelete, chatbot_id, itemType, showSettings }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [inputName, setInputName] = useState('');
    const onClose = () => setIsOpen(false);
    const cancelRef = React.useRef();
    const toast = useToast();
    const navigate = useNavigate();
    const axios = useAxios()
    const [selectedMode, setSelectedMode] = useState('bubble');

    const scriptContent = `
    <!-- Include the JavaScript file -->
    <script src="https://res.cloudinary.com/dataspeak/raw/upload/v1695764645/widget.js"></script>
    
    <!-- Where you want the chat interface to be mounted -->
    <div id="chat-interface"></div>
    
    <script>
        window.ChatbotConfig = {
            chatbotId: '${chatbot_id}',
            mode: '${selectedMode}'
        };
        // Initialize the chat interface
        window.renderChatInterface('#chat-interface');
    </script>
    `;



    const [isScriptModalOpen, setIsScriptModalOpen] = useState(false);
    const onScriptModalClose = () => setIsScriptModalOpen(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(scriptContent).then(() => {
            toast({
                title: "Copied",
                description: "Script copied to clipboard.",
                status: "success",
                duration: 2000,
                isClosable: true,
            });
        });
    };

    const handleDelete = async () => {
        if (itemType == 'chatbot' && inputName !== name) {
            toast({
                title: "Error.",
                description: "Names do not match. Cannot proceed with delete.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }
        const url = itemType === 'chatbot'
            ? `/chatbots/${chatbot_id}`
            : `/sessions/${chatbot_id["$oid"]}`;
        try {
            await axios.delete(process.env.REACT_APP_CHATAPI_URL + url, {
                withCredentials: true
            });
            onDelete(chatbot_id);
            setIsOpen(false);
        } catch (error) {
            console.error(`Error deleting ${itemType}:`, error);
        }
    };
    const borderColor = useColorModeValue("gray.300", "gray.700");
    const navigateToSettings = () => {
        navigate(`/settings/${chatbot_id}`);
    };
    const textColor = useColorModeValue("primary.900", "primary.100");
    const iconColor = useColorModeValue("gray.900", "primary.200");
    const dangerIconColor = useColorModeValue("red.500", "red.400");
    const handleDeleteFast = async () => {
        const url = itemType === 'chatbot'
            ? `/chatbots/${chatbot_id}`
            : `/sessions/${chatbot_id["$oid"]}`;
        try {
            await axios.delete(process.env.REACT_APP_CHATAPI_URL + url, {
                withCredentials: true
            });
            onDelete(chatbot_id);
        } catch (error) {
            console.error(`Error deleting ${itemType}:`, error);
        }
    };

    return (
        <>
            <HStack
                spacing={4}
                padding={3}
                borderWidth={"1px"}
                borderRadius="0"
                borderColor={borderColor}
                alignItems="center"
                justify="space-between"
            >
                <Text fontSize="lg" fontWeight="medium" color={textColor}>{name}</Text>
                <HStack spacing={4}>

                    {showSettings &&
                        <>
                            {/* This is the new modal for the script */}
                            <Modal isOpen={isScriptModalOpen} onClose={onScriptModalClose} size={"lg"}>
                                <ModalOverlay />
                                <ModalContent maxW={"640px"}>
                                    <ModalHeader>Embed Code</ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        <Box mb={4}>
                                            <Text fontWeight="bold" mb={2}>Select chat interface mode:</Text>
                                            <Select
                                                value={selectedMode}
                                                onChange={(e) => setSelectedMode(e.target.value)}
                                            >
                                                <option value="bubble">Bubble</option>
                                                <option value="popover">Popover</option>
                                                <option value="full">Full</option>
                                            </Select>
                                        </Box>

                                        <Box position="relative">
                                            <SyntaxHighlighter language="html" style={dracula}>
                                                {scriptContent}
                                            </SyntaxHighlighter>
                                            <IconButton
                                                position="absolute"
                                                top="5px"
                                                right="5px"
                                                aria-label="Copy Code"
                                                icon={<Icon as={FaCopy} w={4} h={4} />}
                                                onClick={copyToClipboard}
                                                size="sm"
                                                variant="ghost"
                                            />
                                        </Box>

                                        <Box mt={4}>
                                            <Text fontWeight="bold" mb={2}>How to use:</Text>
                                            <Text fontSize="sm">
                                                1. Copy the above script.
                                            </Text>
                                            <Text fontSize="sm">
                                                2. Paste the `{"<script>"}` tags at the end of your website's `{"<head>"}` section or before the closing `{"</body>"}` tag, depending on where you want the script to load.
                                            </Text>

                                            <Text fontSize="sm">
                                                3. Place the `<div id="chat-interface"></div>` where you want the chat interface to appear on your page.
                                            </Text>

                                            {/* Adding a gradient separator line */}
                                            <Box mt={4} bgGradient="linear(to-r, transparent, gray.500, transparent)" h="1px" w="100%" />

                                            <Center mt={4}>
                                                <Button colorScheme="primary" mt={3} mb={5} onClick={onScriptModalClose}>Done</Button>  {/* Increased padding at the bottom */}
                                            </Center>
                                        </Box>
                                    </ModalBody>



                                </ModalContent>
                            </Modal>

                            {/* Add this button to your HStack where other buttons are */}
                            <IconButton
                                aria-label="Embed Code"
                                icon={<Icon as={FaCode} w={6} h={6} />}
                                color={iconColor}
                                onClick={() => setIsScriptModalOpen(true)}
                                variant="ghost"
                            />
                            <IconButton
                                aria-label="Settings"
                                icon={<Icon as={FaCog} w={6} h={6} />}
                                color={iconColor}
                                onClick={navigateToSettings}
                                variant="ghost"
                            />
                        </>
                    }
                    <IconButton
                        aria-label="Delete"
                        icon={<Icon as={FaTrash} w={6} h={6} />}
                        colorScheme="red"
                        variant="ghost"
                        onClick={() => setIsOpen(true)}
                    />
                    <Button
                        onClick={onClick}
                    >
                        {itemType == 'chatbot' ? 'New Chat' : 'Open Chat'}
                    </Button>
                </HStack>
            </HStack>

            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Delete Chatbot
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            Are you sure you want to delete {name}? Please type the name of the chatbot to confirm.
                            <Input value={inputName} onChange={(e) => setInputName(e.target.value)} placeholder="Chatbot Name" />
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme="red" onClick={() => itemType === 'chatbot' ? handleDelete() : handleDeleteFast()} ml={3}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
};

export default ChatItem;
