import {useLocation} from "react-router-dom";
import ReactGA from "react-ga";
import {useEffect} from "react";

function PageViewAnalytics() {
    const location = useLocation();

    const trackingId = "G-3NYTT57KG3";
    ReactGA.initialize(trackingId);

    useEffect(() => {
        ReactGA.pageview(location.pathname + location.search);
    }, [location]);

    return null; // this component doesn't render anything
}

export default PageViewAnalytics