import React, { useState, useEffect } from 'react';
import {
    VStack,
    HStack,
    Text,
    useColorModeValue,
    Box,
    Icon,
    IconButton,
    Spinner,
    Center
} from '@chakra-ui/react';
import { FaKey, FaTrash } from 'react-icons/fa';
import instance from "../../utils/axiosConfig";
import { useToast } from '@chakra-ui/react';
import useAxios from "../../hooks/useAxios";

const ListAuthKeys = () => {
    const [authKeys, setAuthKeys] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const toast = useToast();
    const textColor = useColorModeValue("primary.900", "primary.100");
    const borderColor = useColorModeValue("gray.300", "gray.700");
    const axios = useAxios();

    useEffect(() => {
        const fetchAuthKeys = async () => {
            try {
                const response = await instance.get(process.env.REACT_APP_AUTH_URL + '/users/me/keys', { withCredentials: true });
                const keys = response.data.auth_keys || [];
                setAuthKeys(keys);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching auth keys:", error);
                toast({
                    title: "Error fetching auth keys.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
                setIsLoading(false);
            }
        };

        fetchAuthKeys();
    }, [toast]);

    const handleDelete = async (keyToDelete) => {
        try {
            await axios.delete(process.env.REACT_APP_AUTH_URL + '/users/me/keys', {
                data: { auth_key: keyToDelete },
                withCredentials: true
            });
            setAuthKeys(authKeys.filter(key => key.key !== keyToDelete));  // Update state to remove the deleted key

        } catch (error) {
            console.error("Error deleting auth key:", error);
        }
    }

    return (
        <VStack align="stretch" spacing={4} padding={3}>
            {isLoading ? (
                <Center>
                    <Spinner />
                </Center>
            ) : (
                authKeys.length > 0 ? (
                    authKeys.map(authKey => (
                        <HStack
                            key={authKey.key}
                            spacing={4}
                            padding={2}
                            borderWidth={1}
                            borderRadius="0"
                            alignItems="center"
                            borderColor={borderColor}
                        >
                            <Icon as={FaKey} color={textColor} w={6} h={6} />
                            <VStack align="start">
                                <Text fontSize="lg" fontWeight="medium" color={textColor}>
                                    {authKey.key}
                                </Text>
                                <Text fontSize="sm" color={textColor}>
                                    Last used: {authKey.last_used ? new Date(authKey.last_used).toLocaleString() : "Never"}
                                </Text>
                            </VStack>
                            <Box flexGrow={1} />
                            <IconButton
                                aria-label="Delete Auth Key"
                                icon={<FaTrash />}
                                colorScheme="red"
                                variant="outline"
                                onClick={() => handleDelete(authKey.key)}
                            />
                        </HStack>
                    ))
                ) : (
                    <Center>
                        <Text>No Auth Keys Available</Text>
                    </Center>
                )
            )}
        </VStack>
    );
};

export default ListAuthKeys;
