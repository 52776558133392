import React from 'react';
import { Box, useColorModeValue, Icon, Text } from '@chakra-ui/react';
import 'font-awesome/css/font-awesome.min.css';

const fileIconMap = {
    pdf: 'fa-file-pdf-o',
    txt: 'fa-file-text-o',
    raw: 'fa-edit',
    url: 'fa-link',
    github: 'fa-github',
    youtube: 'fa-youtube-play'
};

const FileTypesGrid = ({ onFileTypeClick, selectedType }) => {
    const bgColor = useColorModeValue("gray.100", "gray.700");
    const selectedBgColor = useColorModeValue("blue.100", "blue.700");
    const textColor = useColorModeValue("gray.800", "gray.200");
    const iconColor = useColorModeValue("gray.800", "gray.200");

    return (
        <Box display="flex" flexWrap="wrap" justifyContent="center" gap="20px" mt="20px">
            {['pdf', 'txt', 'raw', 'url', 'github', 'youtube'].map((type) => (
                <Box
                    key={type}
                    onClick={() => onFileTypeClick(type)}
                    w="80px"
                    h="80px"
                    borderRadius="10px"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    bgColor={type === selectedType ? selectedBgColor : bgColor}
                    cursor="pointer"
                    boxShadow="sm"
                    transition="0.3s"
                    fontWeight="bold"
                    _hover={{
                        boxShadow: "md"
                    }}
                >
                    <i className={`fa ${fileIconMap[type]} fa-lg`} style={{ marginBottom: '5px' }}></i>
                    <Text fontSize="14px" color={textColor}>{type.toUpperCase()}</Text>
                </Box>
            ))}
        </Box>
    );
};

export default FileTypesGrid;

