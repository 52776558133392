import FileTypesGrid from "../chat_interface/FileTypesGrid";
import {Text} from "@chakra-ui/react";

const DataSourceSelector = ({ onFileTypeClick, selectedType }) => {
    return (
        <>
            <Text textAlign="center" mb={2}>Select your data source:</Text>
            <FileTypesGrid onFileTypeClick={onFileTypeClick} selectedType={selectedType} />
        </>
    );
};

export default DataSourceSelector