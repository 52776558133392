import {Button, Spinner} from "@chakra-ui/react";

const IngestionButton = ({ isUploading, loadingText, canCreate, onClick }) => {
    return (
        <Button colorScheme="teal" onClick={onClick} isDisabled={!canCreate} size="lg" shadow="md" fontWeight="bold">
            {isUploading ? (
                <>
                    <Spinner size="sm" mr="2" />
                    {loadingText}
                </>
            ) : (
                'Create'
            )}
        </Button>
    );
};

export default IngestionButton