import axios from 'axios';
import { useToast } from '@chakra-ui/react';

const instance = axios.create();

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

export const refreshToken = async () => {
    try {
        // Get the refresh token from the cookie (assuming you store refresh tokens in cookies)
        const refreshToken = document.cookie
            .split('; ')
            .find(row => row.startsWith('refreshToken='))
            .split('=')[1];

        const response = await instance.post(process.env.REACT_APP_AUTH_URL+'/refresh', {
            refreshToken: refreshToken,
        });
        // document.cookie = `jwt_token=${response.data.token}`; // Store the token in a cookie
        return response.data.token;
    } catch (error) {
        return Promise.reject(error);
    }
};

instance.interceptors.response.use(response => {
    return response;
}, async error => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
            return new Promise((resolve, reject) => {
                failedQueue.push({ resolve, reject });
            }).then(token => {
                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                return instance(originalRequest);
            }).catch(err => {
                return Promise.reject(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        return refreshToken().then(token => {
            isRefreshing = false;
            processQueue(null, token);
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            return instance(originalRequest);
        }).catch(err => {
            processQueue(err, null);
            return Promise.reject(err);
        });
    }

    return Promise.reject(error);
});

export default instance;
