// YouTubeComponent.jsx

import React from 'react';
import { Input, VStack, Text, HStack, Icon } from '@chakra-ui/react';
import { FaYoutube } from 'react-icons/fa'; // Import the YouTube icon

const YouTubeComponent = ({ onLinkChange, ytLink }) => {
    return (
        <VStack spacing={4}>
            <HStack spacing={2}>
                <Icon as={FaYoutube} boxSize={5} color="red.500" />
                <Text fontWeight="bold">YouTube Transcript</Text>
            </HStack>
            <Input
                onChange={onLinkChange}
                placeholder="Enter YouTube video link"
                value={ytLink}
            />
        </VStack>
    );
};

export default YouTubeComponent;
