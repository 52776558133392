// ChatbotContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import instance from '../utils/axiosConfig';
import axios from "axios"
const ChatbotContext = createContext();

export const ChatbotProvider = ({ children }) => {
    const [chatbots, setChatbots] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const updateChatbotData = async (chatbotId, model, relevantTextsAmount, templates) => {
        setIsLoading(true);
        try {


            const response = await axios.put(`${process.env.REACT_APP_INGESTION_URL}/chatbots/${chatbotId}`, {
                'chat_settings': {
                    'model' : model,
                    'relevant_texts_amount' : relevantTextsAmount,
                    'prompt_templates': templates
                },
            }, {
                withCredentials: true,
                timeout: 1200000 // 30 seconds
            });
            return response;
        } catch (error) {
            return error;
        } finally {
            setIsLoading(false);
        }
    };

    const refreshChatbots = async () => {
        try {
            setIsLoading(true);
            const response = await instance.get(process.env.REACT_APP_CHATAPI_URL + '/chatbots', {
                withCredentials: true
            });
            setChatbots(response.data.chatbots);
        } catch (error) {
            console.error('Error fetching chatbots:', error);
            setError("Failed to fetch chatbots");
        } finally {
            setIsLoading(false);
        }
    };
    const getChatbotSettingsById = (chatbotId) => {
        const chatbot = chatbots.find(bot => {
            return bot._id['$oid'] === chatbotId
        });
        return chatbot ? chatbot.chat_settings : null;
    };
    useEffect(() => {
        refreshChatbots();
    }, []);

    return (
        <ChatbotContext.Provider value={{ chatbots, setChatbots, isLoading, error, refreshChatbots, updateChatbotData, getChatbotSettingsById}}>
            {children}
        </ChatbotContext.Provider>
    );
};

export const useChatbots = () => {
    const context = useContext(ChatbotContext);
    if (context === undefined) {
        throw new Error('useChatbots must be used within a ChatbotProvider');
    }
    return context;
};
