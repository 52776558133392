import React from 'react';
import { Button, useToast } from '@chakra-ui/react';
import instance from "../../utils/axiosConfig";
import useAxios from "../../hooks/useAxios";

const GenerateAuthKeys = ({onGenerate}) => {
    const toast = useToast();
    const axios = useAxios()
    const generateAuthKey = () => {
        // No data payload for this example since the endpoint is just generating a key.
        axios.post(process.env.REACT_APP_AUTH_URL + '/users/me/keys', {}, {withCredentials: true})
            .then((response) => {
                onGenerate(response.data.auth_key)
            })
            .catch((error) => {
            });
    }

    return (
        <Button colorScheme="blue" onClick={generateAuthKey}>
            Generate Auth Key
        </Button>
    );
};

export default GenerateAuthKeys;
