import React, { useState, useEffect } from 'react';
import { Box, VStack, Button, Text, Heading, Spacer, Flex, useToast } from '@chakra-ui/react';
import SelectModel from '../chat_settings/SelectModel';
import RelevantTextsInput from '../chat_settings/RelevantTextsInput';
import PromptTemplates from "../chat_interface/PromptTemplates";
import FileHandler from "../types/FileHandler";
import { useParams } from "react-router-dom";
import {useChatbots} from "../../hooks/chatbotContext";

const ExtendedChatSettings = ({ ...props }) => {
    const toast = useToast();
    const { chatbotId } = useParams();
    const { chatbots, updateChatbotData, isLoading, error, resetError } = useChatbots();

    const [model, setModel] = useState('gpt-3.5-turbo');
    const [relevantTextsAmount, setRelevantTextsAmount] = useState();
    const [templates, setTemplates] = useState([]);
    const [name, setName] = useState("");

    useEffect(() => {
        const chatbotData = chatbots.find(chatbot => chatbot._id["$oid"] === chatbotId);
        if (chatbotData) {
            setModel(chatbotData.chat_settings?.model);
            setRelevantTextsAmount(chatbotData.chat_settings?.relevant_texts_amount);
            setTemplates(chatbotData.chat_settings?.prompt_templates);
            setName(chatbotData.name);
        }
    }, [chatbotId, chatbots]);

    const handleSave = async (model, relevantTextsAmount) => {
        const response = await updateChatbotData(chatbotId, model, relevantTextsAmount, templates);
        if (response && response.status === 200) {
            toast({
                title: "Success",
                description: "Settings saved successfully!",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } else {
            toast({
                title: "Error",
                description: error || "Error saving settings",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    return (
        <Flex direction="column" width="100%" maxWidth="800px" mx="auto">
            {/* Box for Add More Data via FileHandler */}
            <Box p={4} borderRadius="md" boxShadow="sm" border="1px solid" borderColor="gray.700" width="100%"  mb={5}>
                <FileHandler
                    chatbotId={chatbotId}
                    name={name}
                    onSuccess={() => toast({
                        title: "Success",
                        description: "Data added successfully!",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    })}
                    onError={() => toast({
                        title: "Error",
                        description: "Error adding data",
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    })}
                />
            </Box>

            {/* Spacer to provide some distance between the boxes */}
            <Spacer height="20px" />

            {/* Original box for the other components */}
            <Box p={4} borderRadius="lg" boxShadow="base" width="100%">
                <VStack spacing={4} align="stretch">
                    <Heading>Settings for {name}</Heading>
                    {templates && <PromptTemplates templates={templates} setTemplates={setTemplates} onUpdate={() => null}></PromptTemplates>}
                    <RelevantTextsInput value={relevantTextsAmount} onChange={(e) => setRelevantTextsAmount(e)} />
                    <SelectModel value={model} onChange={(e) => setModel(e)} />
                    <Button colorScheme="blue" onClick={() => handleSave(model, relevantTextsAmount)} isLoading={isLoading}>Save</Button>
                </VStack>
            </Box>
        </Flex>
    );
};

export default ExtendedChatSettings;
