import React, { useState } from 'react';
import {
    VStack,
    Button,
    useColorModeValue,
    Box
} from '@chakra-ui/react';
import GenerateAuthKey from './GenerateAuthKeys';  // make sure to adjust the import path
import ListAuthKeys from './ListAuthKeys';        // make sure to adjust the import path

const ManageAuthKeys = () => {
    const [isGenerated, setIsGenerated] = useState(false);
    const bgColor = useColorModeValue("gray.100", "gray.900");

    const handleKeyGeneration = () => {
        // Handle whatever you want after key generation, for now, I'll simply refresh the list
        setIsGenerated(prev => !prev);
    }

    return (
        <VStack spacing={4} p={5} bg={bgColor} borderRadius="lg" boxShadow="md" align="stretch">
            <Box>
                <GenerateAuthKey onGenerate={handleKeyGeneration} />
            </Box>
            <Box mt={5} borderWidth={1} borderRadius="lg" p={3}>
                <ListAuthKeys key={isGenerated} />
            </Box>
        </VStack>
    );
};

export default ManageAuthKeys;
