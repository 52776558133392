import React, { useRef, useEffect } from 'react';
import {
    Box,
    Input,
    Button,
    VStack,
    HStack,
    useMediaQuery,
    Textarea,
    Text,
    Avatar,
    Divider,
    useColorMode,
    IconButton, useToast
} from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import {CopyIcon} from "@chakra-ui/icons";
import ChatSuggestions from "./ChatSuggestions";
import {useChatbots} from "../../hooks/chatbotContext";

// This is your CopyButton component
const CopyButton = ({ content }) => {
    const toast = useToast();

    const handleCopyClick = () => {
        navigator.clipboard.writeText(content).then(
            function () {
                toast({
                    title: "Copied!",
                    description: "Content copied to clipboard.",
                    status: "success",
                    duration: 1500,
                    isClosable: true,
                });
            },
            function (err) {
                console.error('Could not copy text: ', err);
                toast({
                    title: "Error",
                    description: "Could not copy the content.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        );
    };

    return (
        <IconButton
            icon={<CopyIcon />}
            size="sm"
            position="absolute"
            top="5px"
            right="5px"
            onClick={handleCopyClick}
        />
    );
};

const MarkdownComponents = {
    code: ({ node, inline, className, children, ...props }) => {
        const match = /language-(\w+)/.exec(className || '');
        const codeContent = String(children).replace(/\n$/, '');
        return match ? (
            <div style={{ position: 'relative' }}>
                <SyntaxHighlighter style={dracula} language={match[1]} PreTag="div" className={className}>
                    {codeContent}
                </SyntaxHighlighter>
                <CopyButton content={codeContent} />
            </div>
        ) : (
            <code className={className} {...props}>
                {children}
            </code>
        );
    },
};

const MessageLine = ({ message }) => {
    const { colorMode } = useColorMode();
    const userIcon = message.user ? 'https://path/to/user/icon.png' : 'https://path/to/bot/icon.png';
    const dividerColor = colorMode === 'dark' ? "#555" : "#d3d3d3";

    return (
        <>
            <VStack spacing={2} align="stretch">
                <HStack spacing={2}>
                    <Avatar size="sm" src={userIcon} bg={colorMode === 'dark' ? "gray.600" : "white"} />
                    <Box flex="1" p={2} borderRadius="md">
                        <ReactMarkdown components={MarkdownComponents} children={message.text} />
                    </Box>
                </HStack>
            </VStack>
            <Divider bg={`linear-gradient(to right, transparent, ${dividerColor}, transparent)`} h="1px" my={2} />
        </>
    );
};

const ChatSection = ({
                         query,
                         messages,
                         handleQueryChange,
                         handleChat,
                         handleKeyPress,
                         chatName,
                         isAwaitingResponse,
                         chatId,
                         suggestionClick
                     }) => {
    const [isMobile] = useMediaQuery('(min-width: 768px)');
    const messagesEndRef = useRef(null);
    const { getChatbotSettingsById } = useChatbots();

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    const { colorMode } = useColorMode();

    return (
        <VStack width={isMobile ? "100%" : "100%"} position={'relative'} spacing={4}>
            <Text fontSize="2xl">{chatName}</Text>

            <VStack
                height="65vh"
                overflowY="scroll"
                width={"100%"}
                p={2}
                bg={colorMode === 'dark' ? 'gray.700' : 'gray.100'}
                borderRadius="md"
            >
                <VStack spacing={2} align="stretch" width={"100%"}>
                    {messages.map((message, index) => (
                        <MessageLine key={index} message={message} />
                    ))}
                    <div ref={messagesEndRef} />
                </VStack>
            </VStack>

            <ChatSuggestions suggestedQuestions={getChatbotSettingsById(chatId)} onClick={suggestionClick}/>

            <HStack spacing={3} width={"100%"}>
                <Textarea
                    flex="1" // Take up most of the available width
                    placeholder="Query"
                    value={query}
                    onChange={handleQueryChange}
                    onKeyPress={handleKeyPress}
                    bg={colorMode === 'dark' ? 'gray.900' : 'white'}
                />

                <Button
                    flexShrink="0" // Ensure button does not shrink smaller than its content
                    colorScheme="green"
                    isLoading={isAwaitingResponse}
                    onClick={handleChat}
                >
                    Ask
                </Button>
            </HStack>
        </VStack>
    );
};


export default ChatSection;
