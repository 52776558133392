import React, { useState } from 'react';
import Login from './Login';
import Signup from './SignUp';
import { Box, Button, Center, Flex, useColorModeValue } from '@chakra-ui/react';

const UserAuth = ({ onLoginSuccess }) => {
    const [isLoginView, setIsLoginView] = useState(true);

    const handleLoginSuccess = () => {
        onLoginSuccess();
    };

    const toggleView = () => {
        setIsLoginView(prevView => !prevView);
    };

    return (
        <Center h="100vh" bg={useColorModeValue("secondary.100", "secondary.900")}>
            <Box p={8} w={["90%", "400px"]} borderRadius="md" boxShadow="lg" bg={useColorModeValue("white", "secondary.700")}>
                {isLoginView
                    ? <Login onLoginSuccess={handleLoginSuccess} />
                    : <Login onLoginSuccess={handleLoginSuccess} />
                }
                {/*<Center mt={4}>*/}
                {/*    <Button onClick={toggleView}>*/}
                {/*        {isLoginView ? "Don't have an account? Sign up!" : "Already have an account? Log in!"}*/}
                {/*    </Button>*/}
                {/*</Center>*/}
            </Box>
        </Center>
    );
};

export default UserAuth;
