import { useState, useEffect } from 'react';
import axios from 'axios';
import instance from '../utils/axiosConfig';
import {useChatbotAPI} from "./useChatbotApi";
import { useRef } from 'react';
import socketIOClient from "socket.io-client";
import useAxios from "./useAxios";
import {useSessions} from "./sessionContext";
import {useNavigation} from 'react-router-dom';
import {useChatbots} from "./chatbotContext";
import { v4 as uuidv4 } from 'uuid';
const useChat = ({chatId, sessionId, isAwaitingResponse, setIsAwaitingResponse}) => {
    const [query, setQuery] = useState('');
    const [messages, setMessages] = useState([]);
    const [model, setModel] = useState('gpt-3.5-turbo');
    const [relevantTextsAmount, setRelevantTextsAmount] = useState(10);
    const [templates, setTemplates] = useState([]);
    const [promptTemplates, setPromptTemplates] = useState(['Based on provided extracts:\n{extracts}\n Answer this question: {user_query}']);
    const { fetchChatbotData, updateChatbotData, isLoading } = useChatbotAPI(chatId);
    const { chatbots } = useChatbots()
    const { sessions, refreshSessions } = useSessions();
    const ENDPOINT = window.location.hostname === 'local.dataspeak.io'
        ? "wss://local.dataspeak.io:8088"
        : "wss://websocket.dataspeak.io";
    const socket = useRef(null);
    const axios = useAxios()
    const [roomID, setRoomID] = useState(uuidv4());  // Initialize roomID with a UUID

    const handleWebSocketMessage = (token) => {
        setMessages((prevMessages) => {
            let updatedMessages = [...prevMessages];

            if (updatedMessages.length > 0 && updatedMessages[updatedMessages.length - 1].user === false) {
                updatedMessages[updatedMessages.length - 1].text += token;
            } else {
                updatedMessages.push({ text: token, user: false });
            }

            return updatedMessages;
        });
    };


    useEffect(() => {
        // Determine the WebSocket endpoint based on window's location

        const chatId = localStorage.getItem('chat_id');

        // Initialize socket.io client
        socket.current = socketIOClient(ENDPOINT);

        socket.current.on('connect', () => {
            console.log("Connected, joining room")
            socket.current.emit('join_room', { roomID: roomID }); // Send the roomID when joining
        });

        socket.current.on('message', (data) => {
            if (data.roomID === roomID) {
                handleWebSocketMessage(data.token);
            }
        });

        socket.current.on('disconnect', () => {
        });

        socket.current.on('connect_error', (error) => {
            console.error("Connection error", error)

        });

        return () => {
            // Disconnect the socket when component unmounts
            socket.current.disconnect();
        };
    }, []);


    useEffect(() => {
        const fetchSession = () => {
            console.log(sessions)
            const session = sessions.find((session) => session._id["$oid"] === sessionId);
            if (session) {
                setMessages(session.messages.map((msg) => ({ text: msg.content, user: msg.role === 'user' })));
            }
        };

        const fetchData = async () => {
            // Use the chatbots from the context
            const chatbotData = chatbots.find((chatbot) => {
                return chatbot._id["$oid"] === chatId
            }); // Assuming the chatbot object has an 'id' property
            if (chatbotData) {
                setModel(chatbotData?.chat_settings?.model);
                setRelevantTextsAmount(chatbotData?.chat_settings?.relevant_texts_amount);
                setTemplates(chatbotData?.chat_settings?.prompt_templates);
            } else {
                // Fallback if chatbot is not found in the context
                const data = await fetchChatbotData();
                setModel(data?.chat_settings?.model);
                setRelevantTextsAmount(data?.chat_settings?.relevant_texts_amount);
                setTemplates(data?.chat_settings?.prompt_templates);
            }
        }

        fetchData();
        fetchSession();
    }, [chatId, sessions]);

    const handleQueryChange = (e) => setQuery(e.target.value);
    const handleModelChange = (e) => {
        if (e.target)
            setModel(e.target.value);
        else
            setModel(e)
    }
    const handleRelevantTextsAmountChange = (value) => {
        setRelevantTextsAmount(value);
    }
    function getCookie(name) {
        const value = "; " + document.cookie;
        const parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    }
    const handleChat = () => {
        if (isAwaitingResponse)
            return;
        setIsAwaitingResponse(true);
        const templatesLength = (templates.length)
        // const updatedPromptTemplates = updatePromptTemplatesWithUserQuery();
        const variables = templatesLength.length > 0 ?
            templates.map(t => {
                const variables = {};
                t.variables.forEach(v => {
                    variables[v.key] = v.value;
                });
                return variables;
            }) : [{"user_query": query}]
        console.log(relevantTextsAmount)
        const payload = {
            chat_id: chatId,
            session_id: sessionId,
            semantic_query: query,
            prompt_templates: promptTemplates,
            user_query: query,
            template_variables: variables,
            k: relevantTextsAmount,
            user_id: getCookie('user-id'),
            model: model,
            room_id: roomID  // Include the roomID in the payload
        };

        setMessages([...messages, { text: query, user: true }]);
        setQuery(''); // Clear the input field

        instance.post(process.env.REACT_APP_CHAT_URL+`/chats/${chatId}/response`, payload, {
            headers: [
                {}
            ],
            withCredentials: true
        })
            .then((response) => {
                localStorage.setItem('session_id', response.data.session_id)
                setIsAwaitingResponse(false);
                setMessages((prevMessages) => {
                    let updatedMessages = [...prevMessages];
                    if (updatedMessages.length > 0) {
                        updatedMessages[updatedMessages.length - 1].text = response.data.message; // Overwrite the last message
                    } else {
                        updatedMessages.push({ text: response.data.message, user: false });
                    }
                    return updatedMessages;
                });
                const currentHash = window.location.hash;

                if (currentHash.match(/^#\/interface\/[^\/]+$/)) {
                    refreshSessions();
                    // Update the hash to the #/interface/id/id format.
                    const updatedHash = `#/interface/${chatId}/${response.data.session_id}`;
                    window.location.hash = updatedHash;
                }
// Else, you can handle other formats or conditions if needed.


            })

            .catch((error) => {
                setIsAwaitingResponse(false);
                // Handle error
            });
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleChat();
        }
    };

    return {
        query,
        setQuery,
        relevantTextsAmount,
        setRelevantTextsAmount,
        model,
        setModel,
        messages,
        setMessages,
        handleModelChange,
        handleRelevantTextsAmountChange,
        handleQueryChange,
        handleChat,
        handleKeyPress,
        templates,
        setTemplates,
        promptTemplates,
        setPromptTemplates
    };
};

export default useChat;
