import {SimpleGrid} from "@chakra-ui/react";
import DataSourceSelector from "./DataSourceSelector";
import PdfComponent from "../types/PDFComponent";
import TxtComponent from "../types/TxtComponent";
import RawText from "../types/RawText";
import URLComponent from "../types/URLComponent";
import GitHubComponent from "../types/GithubComponent";
import YouTubeComponent from "../types/YoutubeComponent";

const FileTypeComponents = ({ selectedFileType, onFileChange, onTextChange, text, onOwnerChange, onRepoChange, owner, repo, onLinkChange, ytLink, depth, onDepthChange}) => {
    return <SimpleGrid columns={1} spacing={4}>{renderSelectedComponent()}</SimpleGrid>;

    function renderSelectedComponent() {
        switch (selectedFileType) {
            case 'pdf':
                return <PdfComponent onFileChange={onFileChange} />;
            case 'txt':
                return <TxtComponent onFileChange={onFileChange} />;
            case 'raw':
                return <RawText onTextChange={onTextChange} text={text} />;
            case 'url':
                return <URLComponent onTextChange={onTextChange} text={text} depth={depth} onDepthChange={onDepthChange}/>;
            case 'github':
                return <GitHubComponent onOwnerChange={onOwnerChange} onRepoChange={onRepoChange} owner={owner} repo={repo} />;
            case 'youtube':
                return <YouTubeComponent onLinkChange={onLinkChange} ytLink={ytLink} />;
            default:
                return null;
        }
    }
};

export default FileTypeComponents
