import React, { useState } from 'react';
import {
    Box,
    VStack,
    LinkBox,
    LinkOverlay,
    useColorModeValue,
    Icon,
    HStack,
    IconButton,
    useBreakpointValue,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FaPlus, FaRobot, FaComments, FaSignOutAlt, FaCode, FaBookOpen } from 'react-icons/fa';

const Sidebar = ({ onLogout, isOpen, onToggle }) => {
    const navigate = useNavigate();
    const hoverColor = useColorModeValue("blue.100", "blue.700");
    const textColor = useColorModeValue("gray.700", "gray.300");
    const isMobile = useBreakpointValue({ base: true, md: false });

    const handleClose = () => {
        onToggle(); // Close the drawer
    };

    const menuItems = [
        {
            label: 'Create new Chatbot',
            icon: FaPlus,
            path: '/ingestion'
        },
        {
            label: 'My Chatbots',
            icon: FaRobot,
            path: '/chatbots'
        },
        {
            label: 'My Sessions',
            icon: FaComments,
            path: '/sessions'
        },
        {
            label: 'API Keys',
            icon: FaCode,
            path: '/api' // Modify this to the appropriate route for the Developers page
        },
        {
            label: 'Docs',
            icon: FaBookOpen,
            action: 'docs',
            path: 'https://dataspeak.gitbook.io/docs/'
        },
        {
            label: 'Logout',
            icon: FaSignOutAlt,
            action: 'logout'
        },

    ];

    const btnRef = React.useRef();
    const borderColor = useColorModeValue("gray.200", "gray.700"); // Adjust for dark mode

    const SidebarContent = () => {
        const navigate = useNavigate();

        return (
            <VStack align="stretch" spacing={4}>
                {menuItems.map((item, index) => (
                    <LinkBox key={index} p={2} borderRadius="md" _hover={{ bg: hoverColor }}>
                        <HStack spacing={2}>
                            <Icon as={item.icon} color={textColor} />
                            <LinkOverlay onClick={() => {
                                if (item.action === 'logout') {
                                    onLogout();
                                    onToggle()
                                }
                                else if (item.action == 'docs') {
                                    onToggle()
                                    window.open('https://dataspeak.gitbook.io/docs/', '_blank')
                                }
                                else {
                                    onToggle()
                                    navigate(item.path);
                                }
                            }}>
                                {item.label}
                            </LinkOverlay>
                        </HStack>
                    </LinkBox>
                ))}
            </VStack>
        );
    };

    return (
        <>
            {isMobile ? (
                <>
                    <Drawer
                        isOpen={isOpen}
                        placement="left"
                        onClose={handleClose}
                        finalFocusRef={btnRef}
                    >
                        <DrawerOverlay />
                        <DrawerContent>
                            <DrawerCloseButton />
                            <DrawerHeader>DataSpeak</DrawerHeader>
                            <DrawerBody>
                                <SidebarContent />
                            </DrawerBody>
                        </DrawerContent>
                    </Drawer>
                </>
            ) : (
                <Box width="200px" p={4} borderRight="1px solid" borderColor={borderColor}>
                    <SidebarContent />
                </Box>
            )}
        </>
    );
};

export default Sidebar;
