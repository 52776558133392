import React from 'react';
import { Input, VStack, Text } from '@chakra-ui/react';

const GitHubComponent = ({ onOwnerChange, onRepoChange, owner, repo }) => {
    return (
        <VStack spacing={4}>
            <Text fontWeight="bold">GitHub Information</Text>
            <Input
                onChange={onOwnerChange}
                placeholder="Enter owner name"
                value={owner}
            />
            <Input
                onChange={onRepoChange}
                placeholder="Enter repository name"
                value={repo}
            />
        </VStack>
    );
};

export default GitHubComponent;
