// useRepoData.js

import { useState } from 'react';

const useRepoData = () => {
    const [owner, setOwner] = useState('');
    const [repo, setRepo] = useState('');

    const getRepoData = () => ({
        repo: repo,
        repo_owner: owner
    });

    return {
        owner, setOwner, repo, setRepo, getRepoData
    }
};

export default useRepoData
