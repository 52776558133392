import React, {useEffect, useState} from 'react';
import {Box, Text, Stack, useColorMode, Tooltip, HStack} from '@chakra-ui/react';

const ChatSuggestions = ({ suggestedQuestions, onClick }) => {
    const { colorMode } = useColorMode();

    const truncate = (str, num = 20) =>
        str.length > num ? `${str.slice(0, num)}...` : str;

    const getRandomQuestions = () => {
        if (!suggestedQuestions) return []
        let questions = [...suggestedQuestions.suggested_questions];
        const result = [];

        for (let i = 0; i < 4 && questions.length > 0; i++) {
            const randomIndex = Math.floor(Math.random() * questions.length);
            result.push(questions[randomIndex]);
            questions.splice(randomIndex, 1);
        }

        return result;
    };

    const [randomQuestions, setRandomQuestions] = useState([]);

    useEffect(() => {
        setRandomQuestions(getRandomQuestions());
    }, []);
    return (
        <HStack
            position="absolute"
            bottom="7em"
            left="1%"
            spacing={"calc(1% - 1px)"}
            zIndex={2}
        >
            {randomQuestions.map((question, index) => (
                <Tooltip label={question} placement="top" key={index}>
                    <Box
                        p={2}
                        border="1px"
                        borderColor={colorMode === 'dark' ? 'gray.500' : 'gray.200'}
                        borderRadius="md"
                        bg={colorMode === 'dark' ? 'gray.700' : 'gray.100'}
                        shadow="sm"
                        cursor="pointer"
                        _hover={{ bg: colorMode === 'dark' ? 'gray.600' : 'gray.200' }}
                        width="24%"
                        onClick={() => {
                            onClick(question)
                        }}
                    >
                        <Text isTruncated>{truncate(question)}</Text>
                    </Box>
                </Tooltip>
            ))}
        </HStack>
    );
};

export default ChatSuggestions;
