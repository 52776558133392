import React from 'react';
import { Box, List, ListItem, Text, Heading } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import ChatItem from './ChatItem';
import { useSessions} from '../../hooks/sessionContext'; // Make sure to update the path

const ChatSessions = () => {
    const { sessions, isLoading, deleteSession } = useSessions();
    const navigate = useNavigate();

    // const handleDelete = async (session_id) => {
    //     try {
    //         setSessions(prevSessions => prevSessions.filter(session => session._id !== session_id)); // Remove deleted session
    //     } catch (error) {
    //         console.error('Error deleting session:', error);
    //     }
    // };

    const handleOpenSession = (session_id, chat_id) => {
        navigate(`/interface/${chat_id['$oid']}/${session_id['$oid']}`);
    };

    return (
            <Box p={6} borderRadius="lg">
                <Heading mb={4} textAlign="center">Your Chat Sessions</Heading>
                {isLoading ? (
                    <Text textAlign="center" mt={4}>Loading...</Text>
                ) : Array.isArray(sessions) && sessions.length > 0 ? (
                    <List spacing={4}>
                        {sessions.map((session, index) => (
                            <ListItem key={index}>
                                <ChatItem
                                    name={session.title}
                                    onClick={() => handleOpenSession(session._id, session.chatbot_id)}
                                    onDelete={deleteSession}
                                    itemType={"session"}
                                    chatbot_id={session._id}
                                />
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    <Text textAlign="center" mt={4} fontStyle="italic">No chat sessions available</Text>
                )}
            </Box>
    );
};

export default ChatSessions;
