import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
    styles: {
        global: (props) => ({
            body: {
                color: props.colorMode === "dark" ? "white" : "black",
                bg: props.colorMode === "dark" ? "gray.800" : "white",
            },
        }),
    },
    colors: {
        primary: {
            100: "#DFF8F1",
            200: "#ABEED3",
            300: "#79E5C5",
            400: "#47DBB7",
            500: "#00D1A1",
            600: "#00B98C",
            700: "#009F75",
            800: "#007E5D",
            900: "#005D45",
        },
        secondary: {
            100: "#F7F8F9",
            200: "#E5E7E9",
            300: "#D3D5D9",
            400: "#B0B3B8",
            500: "#8C9197",
            600: "#6B7077",
            700: "#4F535A",
            800: "#34373E",
            900: "#1A1B22",
        },
    },
    fonts: {
        heading: "'Roboto', sans-serif",
        body: "'Roboto', sans-serif",
    },
    components: {
        Button: {
            baseStyle: {
                fontWeight: "bold",
                borderRadius: "0", // Square button shape
                transition: "all 0.3s ease", // Transition effect
            },
            variants: {
                primary: {
                    bg: "primary.500",
                    color: "white",
                    _hover: {
                        bg: "primary.600",
                    },
                },
            },
        },
        Card: {
            baseStyle: {
                borderRadius: "lg",
                boxShadow: "lg",
                p: 4,
            },
        },
        Modal: {
            baseStyle: {
                borderRadius: "lg",
                boxShadow: "lg",
                p: 4,
            },
        },
        Input: {
            baseStyle: {
                borderRadius: "0", // Square input shape
                borderColor: "secondary.400", // Border color
                _focus: {
                    borderColor: "primary.500", // Change border color on focus
                },
                _hover: {
                    borderColor: "primary.400", // Change border color on hover
                },
            },
            variants: {
                filled: {
                    // Define any additional filled variant styles if needed
                },
            },
        },
    },
    ol: {
        'padding-left': '20px' /* Adjust based on your design requirements */
    },
    config: {
        initialColorMode: "dark",
        useSystemColorMode: false,
    },

});

export default theme;
