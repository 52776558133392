// useChatbotAPI.js
import { useState } from 'react';
import { useToast } from "@chakra-ui/react";
import instance from "../utils/axiosConfig";
import useAxios from "./useAxios";

export const useChatbotAPI = (chatbotId) => {
    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);

    const axios = useAxios()

    const fetchChatbotData = async () => {
        console.log("Fetching", chatbotId)
        if (!chatbotId) return;
        try {
            const response = await instance.get(`${process.env.REACT_APP_INGESTION_URL}/chatbots/${chatbotId}`, {
                withCredentials: true
            });
            return response.data;
        } catch (error) {
            console.error("Error fetching chatbot data:", error);
        }
    };

    const updateChatbotData = async (model, relevantTextsAmount, templates) => {
        setIsLoading(true);
        try {


            const response = await axios.put(`${process.env.REACT_APP_INGESTION_URL}/chatbots/${chatbotId}`, {
                'chat_settings': {
                    'model' : model,
                    'relevant_texts_amount' : relevantTextsAmount,
                    'prompt_templates': templates
                },
            }, {
                withCredentials: true,
                timeout: 1200000 // 30 seconds
            });
            return response;
        } catch (error) {
            return error;
        } finally {
            setIsLoading(false);
        }
    };

    return { fetchChatbotData, updateChatbotData, isLoading };
}
