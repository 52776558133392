import React, { useEffect } from 'react';
import {
    Box,
    Button,
    Textarea,
    VStack,
    Text,
    HStack,
    Tooltip,
    Icon,
    IconButton,
    useColorModeValue,
} from '@chakra-ui/react';
import {AddIcon, CloseIcon, DeleteIcon, PlusSquareIcon, QuestionOutlineIcon} from '@chakra-ui/icons';

const PromptTemplates = ({ onUpdate, templates, setTemplates }) => {
    const tooltipLabel = "{user_query} is replaced in the chat with the user query, and {extracts} is the extracted documents.";

    useEffect(() => {
        onUpdate(templates); // Notify the parent component
    }, [templates, onUpdate]);

    const handleAddTemplate = () => {
        setTemplates([...templates, '']);
    };

    const handleTemplateChange = (index, value) => {
        const newTemplates = [...templates];
        newTemplates[index] = value;
        setTemplates(newTemplates);
    };

    // const handleRemoveTemplate = (index) => {
    //     const newTemplates = [...templates];
    //     newTemplates.splice(index, 1);
    //     setTemplates(newTemplates);
    // };

    return (
        <Box p={0}>
            <Text mb={4} fontSize="xl" fontWeight="medium">Prompt Template</Text>
            <VStack spacing={4} align="stretch">
                {templates.map((template, index) => (
                    <HStack key={index} spacing={4} align="center">
                        <Textarea
                            placeholder="Enter template"
                            value={template}
                            onChange={(e) => handleTemplateChange(index, e.target.value)}
                            size="md"
                            resize="vertical"
                            flex="1"
                        />
                        {/*<IconButton*/}
                        {/*    icon={<DeleteIcon />}*/}
                        {/*    aria-label="Remove template"*/}
                        {/*    variant="ghost"*/}
                        {/*    colorScheme="red"*/}
                        {/*    onClick={() => handleRemoveTemplate(index)}*/}
                        {/*    size="sm"*/}
                        {/*/>*/}
                    </HStack>
                ))}
                {/*<Button leftIcon={<AddIcon />} colorScheme="blue" onClick={handleAddTemplate}>*/}
                {/*    Add Template*/}
                {/*</Button>*/}
                <Tooltip label={tooltipLabel} hasArrow placement="left">
                    <Icon boxSize={5} as={QuestionOutlineIcon} color="gray.500" />
                </Tooltip>
            </VStack>
        </Box>
    );
};

export default PromptTemplates;
