// FileHandler.js
import React, { useState } from 'react';
import { VStack } from '@chakra-ui/react';
import useFileHandler from '../../hooks/useFileHandler';
import FileTypeComponents from "../ingestion/FileTypeComponents";
import DataSourceSelector from "../ingestion/DataSourceSelector";
import useRepoData from "../../hooks/useRepoData";
import AddDataButton from "../chat_settings/AddDataButton";
import IngestionButton from "../ingestion/IngestionButton";

const FileHandler = ({ onSuccess, onError, name, chatbotId = null }) => {
    const [text, setText] = useState('');
    const {
        file,
        isUploading,
        loadingText,
        selectedFileType,
        onFileChange,
        fileTypeClick,
        handleIngestion,
        handleAddDocuments
    } = useFileHandler();

    const { owner, setOwner, repo, setRepo, getRepoData } = useRepoData();

    const [ytLink, setYtLink] = useState()

    const [depth, setDepth] = useState()

    const onIngestionClick = () => {
        const data = {
            text: text ? text : ytLink,
            name: name
        };

        if (selectedFileType === 'github') {
            data.repoData = getRepoData();
        }

        const isFileUpload = !!file;

        if (chatbotId) {
            handleAddDocuments(chatbotId, data, isFileUpload, onSuccess, onError, depth);
        } else {
            handleIngestion(data, isFileUpload, onSuccess, onError, depth);
        }
    };

    const onLinkChange = (e) => {
        setYtLink(e.target.value)
    }

    const canCreate = () => {
        return name && (file || text || (repo && owner) || ytLink)
    }

    const canUpdate = () => {
        return file || text || (repo && owner) || ytLink
    }

    return (
        <VStack spacing={4}>
            <DataSourceSelector onFileTypeClick={fileTypeClick} selectedType={selectedFileType} />

            <FileTypeComponents
                selectedFileType={selectedFileType}
                onFileChange={onFileChange}
                onTextChange={e => setText(e.target.value)}
                onOwnerChange={e => setOwner(e.target.value)}
                onRepoChange={e => setRepo(e.target.value)}
                owner={owner}
                repo={repo}
                ytLink={ytLink}
                depth={depth}
                onLinkChange={onLinkChange}
                onDepthChange={setDepth}
            />

            {chatbotId ? (
                <AddDataButton
                    isUploading={isUploading}
                    loadingText={loadingText}
                    canCreate={canUpdate}
                    onClick={onIngestionClick}
                />
            ) : (
                <IngestionButton
                    isUploading={isUploading}
                    loadingText={loadingText}
                    canCreate={canCreate}
                    onClick={onIngestionClick}
                />
            )}
        </VStack>
    );

};

export default FileHandler;
