import React from 'react';
import ChatInterface from '../components/chat_interface/ChatInterface';
import { Container, useMediaQuery } from '@chakra-ui/react';

const ChatPage = () => {
    const [isMobile] = useMediaQuery('(max-width: 768px)');
    return (
        <Container maxW="100%" height={"100%"}>
            <ChatInterface />
        </Container>
    );
};

export default ChatPage;
